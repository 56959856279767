//added sandbox environment
// const href = window.location.href;
// let url = "";
// if (href.includes("localhost")) url = process.env.REACT_APP_API_URL_DEV;
// else url = process.env.REACT_APP_API_URL;

export const urlBase = process.env.REACT_APP_API_URL;

export const API_ROUTES = {
  dockets: `${urlBase}/v1/docket/dockets`,
  jobs: `${urlBase}/v1/Jobs/getJob`,
  enterprise: {
    create: urlBase + "/v1/enterprise/createEnterprise",
    get: urlBase + "/v1/enterprise/getEnterprises",
    getByName: urlBase + "/v1/enterprise/getEnterprises?name=",
    delete: urlBase + "/v1/enterprise/deleteEnterprise?name=",
    edit: urlBase + "/v1/enterprise/updateEnterprise?name=",
  },
  workFlow: {
    root: urlBase + "/v1/workFlow",
    add: urlBase + "/v1/workFlow/add",
    execute: urlBase + "/v1/workFlow/execute",
    status: urlBase + "/v1/workFlow/status",
  },
  google: {
    auth: urlBase + "/v1/google/auth",
    validate: urlBase + "/v1/google/authValidate",
    driveFolder: urlBase + "/v1/google/driveFolder",
    uploadJob: urlBase + "/v1/google/uploadJob/",
    listFolders: urlBase + "/v1/google/listFolders",
  },
  notification: {
    root: urlBase + "/v1/user/notification",
  },
  approver: urlBase + "/v1/approver/user",
  invoiceApprove: urlBase + "/v1/jobs/approve",
  tallyConfiguration: urlBase + "/v1/tally/configuration",
  auth: {
    sendOTP: urlBase + "/v1/auth/sendOTP",
  },
  configuration: {
    tally: urlBase + "/v1/configuration/tally",
    genToken: urlBase + "/v1/configuration/genToken",
    list: urlBase + "/v1/configuration/list",
  },
  tally: {
    markPaid: urlBase + "/v1/tally/markPaid",
    getProducts: urlBase + "/v1/tally/getProducts",
    getSuppliers: urlBase + "/v1/tally/getSuppliers",
    getCompanies: urlBase + "/v1/tally/getCompanies",
    getVoucherTypes: urlBase + "/v1/tally/getVoucherTypes",
    getLedgers: urlBase + "/v1/tally/getLedgers",
    getGodowns: urlBase + "/v1/tally/getGodowns",
  },
  job: {
    markPaid: urlBase + "/v1/jobs/markPaid",
    notifySupplier: urlBase + "/v1/jobs/notifySupplier",
    getFileById: urlBase + "/v1/jobs/getFileById",
    duplicateInvoices: urlBase + "/v1/jobs/checkDuplicateInvoices",
    submitByRow: urlBase + "/v1/jobs/submitByRow/",
  },
  stripe: {
    fetchDetails: urlBase + "/v1/stripe/fetchCurrentPlan",
  },
  download: {
    tally: urlBase + "/v1/tally/download",
  },
  user: {
    userGroup: urlBase + "/v1/users/userGroup",
  },
  extract: {
    bulkUpload: urlBase + "/v1/extract/bulkUpload",
  },
  dashboard: {
    enterprise_usages: urlBase + "/v1/dashboard/getlicense",
    statistics: urlBase + "/v1/dashboard/system/statistics",
    usageByOrganizations: urlBase + "/v1/dashboard/system/usageByOrganizations",
  },
};

export const getGoogleServiceAPI = (method, scopes = null) => {
  if (!scopes) {
    return API_ROUTES.google[method];
  }
  return API_ROUTES.google[method] + "?&scopes=" + scopes;
};

export default function getURL(urlKey) {
  return API_ROUTES[urlKey];
}

export const associateLabelURL = (apiName) => {
  const label = {
    update: urlBase + "/v1/labels/associateLabel",
  };
  return label[apiName];
};

export const getlabelURL = (apiName) => {
  const labels = {
    get: urlBase + "/v1/labels/getLabels",
    create: urlBase + "/v1/labels/createLabel",
    delete: urlBase + "/v1/labels/deleteLabel",
    update: urlBase + "/v1/labels/updateLabel",
  };
  return labels[apiName];
};

export const getJobUrl = (apiName, id, enterpriseId, dicketId, engine) => {
  const job = {
    get: urlBase + "/v1/jobs/getJob/" + id,
    update: urlBase + "/v1/jobs/getJob/" + id,
    getJobFile: urlBase + "/v1/jobs/getJobFile?file=",
    fileUpload:
      urlBase +
      `/V1/docket/${enterpriseId}/dockets/${dicketId}/files?engine=${engine ?? "amazon"}`,
  };
  return job[apiName];
};

export const getDocketURL = (enterpriseId, apiName, docketId, fileId) => {
  const dockets = {
    create: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    get: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    delete: urlBase + `/V1/docket/${enterpriseId}/dockets/`,
    update: urlBase + `/V1/docket/${enterpriseId}/dockets/`,
    download: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    downloadSingleDocketFile:
      urlBase + `/V1/docket/${enterpriseId}/dockets/${docketId}/files/${fileId}`,
    updateFileName: urlBase + `/v1/Jobs/getJob`,
    files: urlBase + `/v1/docket/${enterpriseId}/dockets/${docketId}/files`,
  };
  return dockets[apiName];
};

export const getRoleURl = (apiName) => {
  const role = {
    create: urlBase + `/V1/roles/`,
    get: urlBase + `/V1/roles/`,
    delete: urlBase + `/V1/roles/`,
  };
  return role[apiName];
};

export const getUserURl = (apiName) => {
  const user = {
    create: urlBase + `/V1/users/`,
    get: urlBase + `/V1/users/`,
    delete: urlBase + `/V1/users/`,
  };
  return user[apiName];
};

export const getNotificationURl = (apiName, enterpriseId, docketId) => {
  const notification = {
    create: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/create`,
    get: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/get`,
    update: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/update`,
    delete: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/delete`,
    validation: urlBase + `/v1/csnotifications/getcsNotifications`,
    updateSerialNum: urlBase + `/v1/csnotifications/`,
  };
  return notification[apiName];
};

export const getMetadataURL = (apiName) => {
  const route = {
    getCurrency: urlBase + `/v1/metadata/currencyList`,
    getUOM: urlBase + `/v1/metadata/getUOM`,
    getDropdownLists: urlBase + `/v1/metadata/getDropdownLists`,
  };
  return route[apiName];
};
