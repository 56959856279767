import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "@windmill/react-ui";
import { Close, Done, Visibility, VisibilityOff } from "@material-ui/icons";
import { getElementById, validatePassword } from "../components/Common/CommonFunctions";
import { useNavigate } from "react-router-dom";
import TextFieldAlert from "../components/Common/TextFieldAlert";
import { AuthContext } from "../context/AuthContext";

function PasswordSet() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const [visible, setVisible] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [validation, setValidation] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialChars: false,
    min: false,
  });

  const validationData = [
    {
      label: "Minimum password length - 8",
      identity: "min",
    },
    {
      label: "Must contain atleast one uppercase letter",
      identity: "uppercase",
    },
    {
      label: "Must contain atleast one lowercase letter",
      identity: "lowercase",
    },
    {
      label: "Must contain atleast one number between 0 & 9",
      identity: "number",
    },
    {
      label: "Must contain atleast one special character(except space,-)",
      identity: "specialChars",
    },
  ];

  useEffect(() => {
    logout();
  }, [logout]);

  const saveFun = () => {
    const { lowercase, uppercase, number, specialChars, min } = validation;
    let pass1 = getElementById("passwordFirstField");
    let pass2 = getElementById("passwordSecondField");

    if (pass1 !== pass2) setPasswordMatch(true);
    else setPasswordMatch(false);

    if (lowercase && uppercase && number && specialChars && min && pass1 === pass2) {
      navigate("/auth");
      // axios
      //   .post()
      //   .then(() => {})
      //   .catch((err) => console.log(err));
    }
  };

  const reEnterPasswordFun = () => {
    let pass1 = getElementById("passwordFirstField");
    let pass2 = getElementById("passwordSecondField");
    if (pass1 !== pass2) setPasswordMatch(true);
    else setPasswordMatch(false);
  };
  const onchangeFun = (arg) => {
    let { lowercase, uppercase, number, specialChars, min } = validatePassword(arg);
    setValidation(() => {
      return {
        lowercase: lowercase,
        uppercase: uppercase,
        number: number,
        specialChars: specialChars,
        min: min,
      };
    });
  };

  const visibleFun = () => setVisible(!visible);

  const header = (
    <div
      className="pb-3"
      style={{ fontSize: "20px", fontWeight: "500px", color: "#000000" }}
    >
      Set Password
    </div>
  );

  const content = (
    <div className="w-full p-2 mt-2 rounded" style={{ backgroundColor: "#F0F0F0" }}>
      {validationData.map((e, i) => {
        return (
          <div className="flex flex-row py-1" key={i}>
            {validation[e.identity] ? (
              <Done
                className="text-green-500"
                style={{ width: "18px", height: "18px" }}
              />
            ) : (
              <Close className="text-red-500" style={{ width: "18px", height: "18px" }} />
            )}
            <div className="text-xs ml-2 text-gray-500">{e.label}</div>
          </div>
        );
      })}
    </div>
  );

  const footer = (
    <div className="w-full flex flex-row-reverse mt-6">
      <Button className="w-44 h-11 cursor-pointer" onClick={saveFun}>
        SAVE
      </Button>
      <Button
        className="w-44 h-11 mr-8 text-purple-500 cursor-pointer"
        style={{
          backgroundColor: "white",
          border: "2px solid #F5F5F5",
          boxShadow: "0px 2px 6px #F5F5F5",
          borderRadius: "10px",
        }}
        onClick={() => {
          navigate("/auth");
        }}
      >
        CANCEL
      </Button>
    </div>
  );

  return (
    <div
      className="w-screen h-screen flex items-center justify-center"
      style={{ backgroundColor: "#F8FBFF" }}
    >
      <div
        className="h-2/4 bg-white p-9"
        style={{
          fontSize: "24px/35px",
          width: "58%",
          boxShadow: "0px 2px 6px #F5F5F5",
          borderRadius: "10px",
        }}
      >
        {header}
        <div className="flex flex-row justify-between">
          <div style={{ width: "47%" }}>
            <Label text="Password" />
            <div className="relative">
              <Input
                className="w-full h-11 pr-3"
                type={visible ? "text" : "password"}
                id="passwordFirstField"
                onChange={(e) => onchangeFun(e.target.value)}
              />
              <div className="absolute top-2 right-3">
                {visible ? (
                  <VisibilityOff
                    className="cursor-pointer"
                    style={{ color: "#808080", fontSize: 20 }}
                    onClick={visibleFun}
                  />
                ) : (
                  <Visibility
                    className="cursor-pointer"
                    style={{ color: "#808080", fontSize: 20 }}
                    onClick={visibleFun}
                  />
                )}
              </div>
            </div>
            {content}
          </div>

          <div style={{ width: "47%" }}>
            <Label text="Confirm Password" />
            <Input
              className="w-full h-11"
              id="passwordSecondField"
              type={"password"}
              onChange={reEnterPasswordFun}
            />
            <div>
              <TextFieldAlert
                open={passwordMatch === null ? false : passwordMatch}
                message="Password must match with previous password"
              />
            </div>
          </div>
        </div>
        {footer}
      </div>
    </div>
  );
}

const Label = ({ text }) => {
  return (
    <div className="text-sm mb-1" style={{ fontColor: "#646464" }}>
      {text ? text : ""}
    </div>
  );
};

export default PasswordSet;
