import React from 'react';
import { CircularProgress } from '@material-ui/core';

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex items-center justify-center p-6 text-lg font-medium text-gray-600">
      <CircularProgress />
    </div>
  );
}

export default ThemedSuspense
